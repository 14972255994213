import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './components/login';
import Home from './components/home'; 
import axios from 'axios';   
import api from './api';
//import usb from "usb";
//var usb = require('usb')
//var usbDetect = require('usb-detection');

class App extends Component {
 constructor(){
    super();    
    this.state = {
      stripe_id: '',
      stripeData:{
          enable_stripe: false,
          title:'',
          description:'',
          enable_testmode: false,
          test_pub_key:'',
          test_secret_key:'',
          enable_inline_card_form: false,
          statement_descriptor:'',
          enable_capture_charge: false,
          enable_3d_secure: false,
          enable_stripe_modal: false,
          enable_payment_buttons: false,
          payment_button_type:'',
          payment_button_theme:'',
          payment_button_height:'',
          enable_payment_savecards: false,
          enable_log_debug: false
      } 
    }; 
  }

  componentDidMount() {  
    api.get('stripe-config/')
      .then(res => { 
        if(res.data != null && res.data != undefined){
            this.setState({ stripeData: res.data });  
            this.setState({ stripe_id: res.data._id });  
        }
    }); 
  } 
   
  render() {
    return ( 
      <div>
          {
            (localStorage.getItem("usersession") != null && localStorage.getItem("usersession") != undefined) ?
              <Home parentContext={this}></Home>
            :
              <Login parentContext={this}></Login> 
          }  
      </div> 
    );
  }
}

export default App;
