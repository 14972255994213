/* eslint-disable */
import axios from 'axios';

const api = axios.create({
    baseURL: "https://node.protondatadefender.org/",
    //  baseURL: "http://localhost:5000/",

    headers: {
        'Content-Type': 'application/json'
    }
});
export default api;